import React, {useEffect} from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import { NeuCard } from 'neumorphic-ui';
import ProjectCard from "../components/project"
// import {Flipper, Flipped} from "react-flip-toolkit"


const AboutPage = () => {
    return (
      <Layout>
        <SEO
          keywords={['Test']}
          title="About Me"
        />
          {/* <section className="w-full max-w-6xl px-4 mx-auto mt-8 md:mt-24"> */}
          <section>
             
          </section>
      </Layout>

    );
  }
  
  export default AboutPage;
  